import Vue from "vue";
import { ChartPlugin, ColumnSeries, DateTimeCategory, DataLabel, Tooltip, Legend } from "@syncfusion/ej2-vue-charts";
import { getSkeleton } from "@/helpers/chartHelper";
Vue.use(ChartPlugin);
export default Vue.extend({
    props: {
        granularity: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            dataSource: {
                series: [],
                data: [],
                total: 0.0,
                color: null
            },
            /*
            primaryXAxis: {
              valueType: "Category"
            },
             */
            primaryYAxis: {
                labelFormat: "c"
            },
            marker: {
                dataLabel: { visible: true }
            },
            lastSearchData: null
        };
    },
    provide: {
        chart: [ColumnSeries, DateTimeCategory, DataLabel, Tooltip, Legend]
    },
    computed: {
        primaryXAxis() {
            const skeleton = getSkeleton(this.granularity);
            return {
                valueType: "DateTimeCategory",
                skeleton: skeleton
            };
        },
        averageColor() {
            if (this.dataSource.color == undefined || this.dataSource.color == null)
                return "";
            return `background-color: ${this.dataSource.color}; border-color: ${this.dataSource.color}`;
        }
    },
    watch: {
        granularity() {
            if (this.lastSearchData != null) {
                const { startDate, endDate, employeeId, officeId } = this.lastSearchData;
                this.loadData(startDate, endDate, employeeId, officeId);
            }
        }
    },
    methods: {
        async loadData(startDate, endDate, employeeId, officeId) {
            this.lastSearchData = {
                startDate,
                endDate,
                employeeId,
                officeId
            };
            this.dataSource = {
                series: [],
                data: [],
                total: 0.0,
                color: null
            };
            this.$refs.chart.clearSeries();
            this.dataSource = await this.$store.dispatch("statistics/servicesProfit", {
                startDate,
                endDate,
                employeeId,
                officeId,
                granularity: this.granularity
            });
        }
    }
});
